import React from 'react';

function Header() {

  return (
    <div className="bg-white bg-opacity-50 text-[#FF6633] p-2 sm:p-4 flex justify-center items-center italic shadow-lg border border-[#e5e5e5]"
         style={{
           boxShadow: '0 10px 15px -3px rgba(255, 255, 255, 0.2), 0 4px 6px -2px rgba(255, 255, 255, 0.1)'
         }}>
      <div className="font-semibold text-xl flex items-center gap-4">
        Cocktail Bot
      </div>
    </div>
  );
}

export default Header;
