import React, { useState } from 'react';
import Header from './Header';
import ChatArea from './ChatArea';
import InputArea from './InputArea';
import MoodOptions from './MoodOptions';

const CocktailbotApp = () => {
  const [messages, setMessages] = useState([]);
  const [response, setResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showMoodOptions, setShowMoodOptions] = useState(false);

  const sendMessage = (text) => {
    if (text && text.trim()) {
      setResponse({});
      setIsLoading(true);
      setMessages(messages => [...messages, { text, from: 'user' }]);
      fetch('https://cocktailcourier.fleetstudio.com/api/ask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: text })
      })
      .then(response => response.json())
      .then(data => {
        setResponse(data);
        setMessages(messages => [...messages, { text: data.content, from: 'system' }]);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error:', error);
        setMessages(messages => [...messages, { text: 'Error in fetching response', from: 'system' }]);
        setIsLoading(false);
      });
    }
  };

  const getCocktailBasedOnMood = (text) => {
    if (text && text.trim()) {
      setResponse({});
      setIsLoading(true);
      setMessages(messages => [...messages, { text, from: 'user' }]);
      const moodQuestion = text + ' mood'
      fetch('https://cocktailcourier.fleetstudio.com/api/mood', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mood: moodQuestion })
      })
      .then(response => response.json())
      .then(data => {
        setResponse(data);
        setMessages(messages => [...messages, { text: data.content, from: 'system' }]);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error:', error);
        setMessages(messages => [...messages, { text: 'Error in fetching response', from: 'system' }]);
        setIsLoading(false);
      });
    }
  };

  const triggerOrderResponse = () => {
    if (Object.keys(response).length > 0) {
      setIsLoading(true);
      setMessages(messages => [...messages, { text: `The Price of ${response?.name} is $ ${response?.price}. Do you want to proceed to payment?`, from: 'system' }]);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      setMessages(messages => [...messages, { text: "Order placed, thank you!", from: 'system' }]);
      setIsLoading(false);
    }
  }

  const updatePayment = () => {
    setIsLoading(true);
    setMessages(messages => [...messages, { text: "Order placed, thank you!", from: 'system' }]);
    setIsLoading(false);
    fetch('https://cocktailcourier.fleetstudio.com/api/response', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "name": response.name, "price": response.price })
    })
    setResponse({});
  }

  return (
    <div className="bg-white h-full flex flex-col mx-auto lg:mx-20 xl:mx-60 shadow-lg my-10">
      <Header />
      {showMoodOptions ? 
        <MoodOptions selectedMoodOption={getCocktailBasedOnMood} setShowMoodOptions={setShowMoodOptions} /> :
        <ChatArea messages={messages} isLoading={isLoading} triggerOrderResponse={triggerOrderResponse} updatePayment={updatePayment} />
      }
      <InputArea onSendMessage={sendMessage} isLoading={isLoading} setShowMoodOptions={setShowMoodOptions} showMoodOptions={showMoodOptions} />
    </div>
  );
}

export default CocktailbotApp;
