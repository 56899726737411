import React from 'react';
import './App.css';
import useViewportHeight from './useViewportHeight';
import CocktailbotApp from './CocktailbotApp';

function App() {
  const viewportHeight = useViewportHeight();

  document.documentElement.style.setProperty('--app-height', `${viewportHeight}px`);

  return (
    <div style={{ height: 'var(--app-height)' }}>
      <CocktailbotApp />
    </div>
  );
}

export default App;
